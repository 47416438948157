<template>
    <div>
        <PopupSpinner v-if="submitting"></PopupSpinner>
        <div id="query" :style="submitting ? 'opacity: 0.2;' : 'opacity: 1;'">
            <div v-if="documents === null">
                <strong>請輸入密碼訪問</strong>
                <b-form-input v-model="pwd" type="password" placeholder="password"></b-form-input>
            </div>

            <div v-if="documents !== null">
                <strong>後臺管理員您好!</strong>
                <br />

                <button>
                    <download-excel worksheet="sheet1" name="參加名單.xls" type="xls" :fields="json_fields"
                        :data="documents">
                        匯出EXCEL
                    </download-excel>
                </button>

                <br />
                <strong>婚禮參加統計資料</strong>

                <b-table striped hover :items="documents" :fields="fields"></b-table>
            </div>


        </div>
    </div>

</template>

<script>
import axios from 'axios';
import * as Realm from "realm-web";
import PopupSpinner from '../components/PopupSpinner.vue';

axios.defaults.withCredentials = true;

export default {
    name: 'QueryList',
    data() {
        return {
            pwd: '',
            documents: null,
            fields: [{ key: 'name', label: '姓名' },
            { key: 'phone', label: '電話' },
            { key: 'BrideType', label: '新娘/郎親友' },
            { key: 'type', label: '關係' },
            { key: 'number_people', label: '人數' },
            { key: 'number_people_vegetable', label: '吃素人數' },
            { key: 'number_childchair', label: '兒童座椅數' },
            { key: 'number_people_bus', label: '搭車人數' },
            { key: 'NeedPhysicalWeddingInvitation', label: '實體喜帖' },
            { key: 'address', label: '地址' },
            { key: 'update_time', label: '填表時間', sortable: true }],
            json_fields: {
                "姓名": "name",
                "電話": "phone",
                "新娘/郎親友": "BrideType",
                "關係": "type",
                "人數": "number_people",
                "吃素人數": "number_people_vegetable",
                "兒童座椅數": "number_childchair",
                "搭車人數": "number_people_bus",
                "實體喜帖": "NeedPhysicalWeddingInvitation",
                "地址": "address",
                "填表時間": "update_time"
            },
            submitting: false,
        }
    },
    components: {
        PopupSpinner,
    },
    methods: {
        async loginInAndSubmit(password) {
            this.submitting = true;
            const pwd = process.env.VUE_APP_PWD;

            if (password != pwd) {
                alert("密碼錯誤!!");
                this.submitting = false;
                return "";
            }

            // Create an email/password credential
            const email = process.env.VUE_APP_mongodb_user;
            const MongoDB_password = process.env.VUE_APP_mongodb_pwd;

            const credentials = Realm.Credentials.emailPassword(email, MongoDB_password);
            const app = new Realm.App({ id: 'data-fldkf' });
            // Authenticate the user
            try {
                const user = await app.logIn(credentials);
                console.assert(user.id === app.currentUser.id);
                console.log(user);
                this.getDocuments(user.accessToken);
                this.pwd = "";
                return user.accessToken;
            } catch (e) {
                console.log(e);
                this.pwd = "";
                this.submitting = false;
                alert("MongoDB 密碼錯誤!!");
                return "";
            }
        },
        getDocuments(accessToken) {
            var data = JSON.stringify({
                "collection": "wedding",
                "database": "Form",
                "dataSource": "Cluster0",
                "filter": {},
                "sort": { "update_time": 1 },
            });

            var config = {
                method: 'post',
                url: process.env.VUE_APP_mongodb_url + '/find',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
                data: data
            };


            axios(config)
                .then(response => {
                    console.log(response);
                    if (response.status == '200') {
                        this.documents = response.data.documents;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            this.submitting = false;

        },
    },
    watch: {
        pwd: function () {
            if (this.pwd.length == 8) {
                this.loginInAndSubmit(this.pwd);
            }
        }
    }
}
</script>

<style scoped>
#popup_spinner {
    position: absolute;
    top: 25%;
    left: 50%;
    border-radius: 10px;
    background-color: whitesmoke;
    z-index: 3;
}
</style>
<template>
  <div id="pictureBackground">
    <div id="frame">
      <img id="picture" @click="changePicture" :src='require(`../assets/${pictures[pictureIndex]}`)'>
      <div id="prompt">
        <strong>請點選畫框中的圖片</strong>
      </div>
    </div>

  </div>
</template>

<style scoped>
#pictureBackground {
  z-index: 0;

  background-image: url('~@/assets/wall_new.png');
  /* Ensure the background covers the entire div */
  background-size: auto 100%;

  /* Position the background */
  background-position: center;

  /* Make sure the div takes up the entire window */
  width: 100vw;
  height: 100vh;

  /* Optionally prevent the background from repeating */
  background-repeat: no-repeat;

  /* Set a fallback background color */
  background-color: #ffffff;
  /* or any color that suits your design */
}

#frame {
  position: fixed;
  top: v-bind('frame_top');
  left: v-bind('frame_left');
  z-index: 1;
  background-image: url('~@/assets/frame.png');
  background-size: v-bind('frame_width') v-bind('frame_height');
  width: v-bind('frame_width');
  height: v-bind('frame_height');
}

#prompt {
  position: relative;
  z-index: 3;
  left: v-bind('prompt_left');
  top: v-bind('prompt_top');
  font-size: v-bind('prompt_size');
}

#picture {
  position: relative;
  z-index: 2;
  top: v-bind('picture_top');
  left: v-bind('picture_left');
  width: v-bind('picture_width');
  height: v-bind('picture_height');
}
</style>

<script>
export default {
  props: [
    "view_index",
  ],
  data() {
    return {
      pictureIndex: 0,
      pictures: [
        '0E0736BE-0840-4020-9FED-4ABBB4D86591.jpg',
        'F_7249.jpg',
        '007.png',
      ],
      frame_top: '',
      frame_left: '',
      frame_width: '',
      frame_height: '',
      picture_top: '',
      picture_left: '',
      picture_width: '',
      picture_height: '',
      prompt_top: '',
      prompt_size: '',
      frameLayout1: { //for windows width < 440 (mobile phone) Default
        frame_top: '3%',
        frame_width: '277px',
        frame_height: '420px',
        picture_top: '50px',
        picture_left: '30px',
        picture_width: '218px',
        picture_height: '321px',
        prompt_left: '5%',
        prompt_top: '85px',
        prompt_size: '20px',
      },
      frameLayout2: { //for windows width > 1240 
        frame_top: '6%',
        frame_width: '370px',
        frame_height: '560px',
        picture_top: '66px',
        picture_left: '40px',
        picture_width: '291px',
        picture_height: '427px',
        prompt_left: '5%',
        prompt_top: '125px',
        prompt_size: '30px',
      }
      
    }
  },
  created() {
    window.addEventListener("resize", this.ResizePcitureFrame);
  },
  destroyed() {
    window.removeEventListener("resize", this.ResizePcitureFrame);
  },
  mounted() {
    this.ResizePcitureFrame();
  },
  methods: {
    changePicture() {
      if (this.pictureIndex < this.pictures.length - 1) {
        this.pictureIndex += 1;
      }
      else {
        this.$emit('update', 1);//in the end picture,  change to investigation page
      }
    },
    ResizePcitureFrame() {
      if (1240 <= window.innerWidth && window.innerHeight >= 670) {
        this.frame_top = this.frameLayout2.frame_top;
        this.frame_left = Math.floor((window.innerWidth-370)/2)+'px';//centering 
        this.frame_width = this.frameLayout2.frame_width;
        this.frame_height = this.frameLayout2.frame_height;
        this.picture_top = this.frameLayout2.picture_top;
        this.picture_left = this.frameLayout2.picture_left;
        this.picture_width = this.frameLayout2.picture_width;
        this.picture_height = this.frameLayout2.picture_height;
        this.prompt_top = this.frameLayout2.prompt_top;
        this.prompt_left = this.frameLayout2.prompt_left;
        this.prompt_size = this.frameLayout2.prompt_size;   
      }
      else 
      {
        this.frame_top = this.frameLayout1.frame_top;
        // this.frame_left = '50px';
        this.frame_left = Math.floor((window.innerWidth-277)/2)+'px';//centering 
        this.frame_width = this.frameLayout1.frame_width;
        this.frame_height = this.frameLayout1.frame_height;
        this.picture_top = this.frameLayout1.picture_top;
        this.picture_left = this.frameLayout1.picture_left;
        this.picture_width = this.frameLayout1.picture_width;
        this.picture_height = this.frameLayout1.picture_height;
        if(window.innerHeight<470){
          this.prompt_top =  '-80%';
          this.prompt_left = '5%';
          this.prompt_size = this.frameLayout1.prompt_size;
        }else{
          this.prompt_top = this.frameLayout1.prompt_top;
          this.prompt_left = this.frameLayout1.prompt_left;
          this.prompt_size = this.frameLayout1.prompt_size;
        }
      }
    }
  }
};
</script>
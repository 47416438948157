<template>
    <div>
        <PopupSpinner v-if="submitting"></PopupSpinner>
        <div id="query" :style="submitting ? 'opacity: 0.2;' : 'opacity: 1;'">
            <b-form-input v-model="pwd" type="password" placeholder="Enter password"></b-form-input>

            <hr />
            <b-table striped hover :items="documents" :fields="fields">
                <template #cell(downloadButton)="data">
                    <b-button @click="downloadAudio(data.item._id)"><b-icon icon="download"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import * as Realm from "realm-web";
import { base64ToBlob } from '../utility'
import PopupSpinner from '../components/PopupSpinner.vue';

axios.defaults.withCredentials = true;

export default {
    name: 'QueryList',
    components: {
        PopupSpinner,
    },
    data() {
        return {
            pwd: '',
            documents: null,
            fields: [{ key: 'name', label: '姓名' },
            { key: 'audio_length', label: '錄音長度', sortable: true },
            { key: 'update_time', label: '填表時間', sortable: true },
            { key: 'downloadButton', label: '下載' }],
            accessToken: null,
            submitting: false,
        }
    },
    methods: {
        async loginInAndSubmit(password) {
            this.submitting = true;
            const pwd = process.env.VUE_APP_PWD;

            if (password != pwd) {
                alert("密碼錯誤!!");
                this.submitting = false;
                return "";
            }
            // Create an email/password credential
            const email = process.env.VUE_APP_mongodb_user;
            const MongoDB_password = process.env.VUE_APP_mongodb_pwd;

            const credentials = Realm.Credentials.emailPassword(email, MongoDB_password);
            const app = new Realm.App({ id: 'data-fldkf' });
            // Authenticate the user
            try {
                const user = await app.logIn(credentials);
                console.assert(user.id === app.currentUser.id);
                console.log(user);
                this.getDocuments(user.accessToken);
                this.pwd = "";
                this.accessToken = user.accessToken;
                return user.accessToken;
            } catch (e) {
                console.log(e);
                this.pwd = "";
                this.submitting = false;
                alert("MongoDB 密碼錯誤!!");
                return "";
            }
        },
        getDocuments(accessToken) {
            var data = JSON.stringify({
                "collection": "audio",
                "database": "Form",
                "dataSource": "Cluster0",
                "filter": {},
                "projection": { 'name': 1,  'update_time': 1, 'audio_length': 1 },
            });

            var config = {
                method: 'post',
                url: process.env.VUE_APP_mongodb_url + '/find',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
                data: data
            };


            axios(config)
                .then(response => {
                    if (response.status == '200') {
                        this.documents = response.data.documents;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            this.submitting = false;

        },
        downloadAudio(id) {
            const data = JSON.stringify({
                "dataSource": "Cluster0",
                "database": "Form",
                "collection": "audio",
                "filter":
                {
                    "_id": { "$oid": id }
                },
            });
            const config = {
                method: 'post',
                url: process.env.VUE_APP_mongodb_url + '/findOne',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken,
                },
                data: data
            };

            axios(config)
                .then(response => {
                    if (response.status == '200') {
                        const base64String = response.data.document.audio_file;

                        var blob = base64ToBlob(base64String, 'audio/wav');

                        const url = URL.createObjectURL(blob);

                        // Create a link element
                        const a = document.createElement('a');

                        // Set the href attribute of the link to the Blob URL
                        a.href = url;

                        // Set the download attribute with the filename
                        a.download = response.data.document.name + '_' + response.data.document.file_name;

                        // Append the link to the document body
                        document.body.appendChild(a);

                        // Click the link to trigger the download
                        a.click();

                        // Remove the link from the document body
                        document.body.removeChild(a);

                        // Revoke the URL to release the resources
                        URL.revokeObjectURL(url);
                    }
                }
                )
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
    watch: {
        pwd: function () {
            if (this.pwd.length == 8) {
                this.loginInAndSubmit(this.pwd);
            }
        }
    }
}
</script>

<style scoped>
#popup_spinner {
    position: absolute;
    top: 25%;
    left: 50%;
    border-radius: 10px;
    background-color: whitesmoke;
    z-index: 3;
}
</style>
<template>
  <div>
    <PictureFrame v-if="view_index === 0" :view_index="view_index" @update="selfUpdateView_index"></PictureFrame>

    <InformationPage v-if="view_index === 1" :view_index="view_index" @update="selfUpdateView_index"></InformationPage>

    <TypeCategory v-if="view_index === 2" :view_index="view_index" @update_view_index="selfUpdateView_index"
      @update_category="selfUpdateCategory"></TypeCategory>

    <FillForm v-if="view_index === 3" :view_index="view_index" :category="category" :accessToken="accessToken"
      @update="selfUpdateView_index" @update_name="selfupdate_name" :type_options="category_types[category]" />

    <RecordAudio v-if="view_index === 5" :accessToken="accessToken" :name="name" :view_index="view_index" @update="selfUpdateView_index"></RecordAudio>

    <NotAttend v-if="view_index === 8" :accessToken="accessToken" :view_index="view_index" @update="selfUpdateView_index"></NotAttend>

    <Finish v-if="view_index === 4 || view_index===6 || view_index===7" :view_index="view_index" @update="selfUpdateView_index"></Finish>

  </div>
</template>

<script>
import FillForm from './FillForm.vue'
import PictureFrame from './PictureFrame.vue'
import InformationPage from './InformationPage.vue'
import TypeCategory from './TypeCategory.vue'
import RecordAudio from './RecordAudio.vue'
import Finish from './Finish.vue'
import NotAttend from './NotAttend.vue'
import * as Realm from "realm-web";

export default {
  name: "main-page",
  components: {
    PictureFrame,
    InformationPage,
    TypeCategory,
    FillForm,
    RecordAudio,
    Finish,
    NotAttend,
  },
  data() {
    return {
      view_index: 0,
      category: null,
      pictureIndex: 0,
      accessToken: null,
      category_types: {
        "Bridegroom": [{ value: null, text: "與新郎的關係" },
        { value: "父親親友", text: "父親親友" },
        { value: "母親親友", text: "母親親友" },
        { value: "新郎親友", text: "新郎朋友" },],
        "Bride": [{ value: null, text: "與新娘的關係" },
        { value: "父親親友", text: "父親親友" },
        { value: "母親親友", text: "母親親友" },
        { value: "新娘親友", text: "新娘朋友" },],
      },
      name: null,
    }
  },
  methods: {
    selfUpdateView_index(val) {
      this.view_index = val;
    },
    selfUpdateCategory(val) {
      this.category = val;
    },
    selfupdate_name(val) {
      this.name = val;
    },
    async getAccessToken() {
      const email = process.env.VUE_APP_mongodb_user;
      const password = process.env.VUE_APP_mongodb_pwd;
      const app = new Realm.App({ id: 'data-fldkf' });
      const credentials = Realm.Credentials.emailPassword(email, password);
      // Authenticate the user
      const user = await app.logIn(credentials);
      // 'App.currentUser' updates to match the logged in user
      this.accessToken = user.accessToken;
    }

  },
  beforeMount: function () {
    this.getAccessToken();
  }

}
</script>

<style scrope></style>
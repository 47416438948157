<template>
  <div id="pictureBackground">
    <div class="container col-11 col-md-9 col-xl-6">
      <div style="font-size: 25px; text-align: center;">你是新娘?新郎的親友?</div>
      <b-input-group class="row d-flex justify-content-center">
        <div @click="category = 'Bridegroom'" class="col-sm-12 col-md-6">
          <img :src='require(`../assets/${BridePicture[0]}`)' width="100%" height="90%">
          <br/>
          <strong>新郎親友點這裡</strong>
        </div>

        <div @click="category = 'Bride'" class="col-sm-12 col-md-6">
          <img :src='require(`../assets/${BridePicture[1]}`)' width="100%" height="90%">
          <br/>
          <strong>新娘親友點這裡</strong>
        </div>
      </b-input-group>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "view_index",
  ],
  data() {
    return {
      category: null,
      BridePicture: [
        'bridegroom.png',
        'bride.png',
      ],
      
    }
  },
  watch: {
    category: function () {
      if (this.category == "Bride") {
        this.$emit('update_view_index', 3);
        this.$emit('update_category', this.category);

      }
      if (this.category == "Bridegroom") {
        this.$emit('update_view_index', 3);
        this.$emit('update_category', this.category);
      }
    }
  }
}
</script>
<style scoped>
#pictureBackground {
  z-index: 0;
  background-image: url('~@/assets/wall_new.png');
  /* Ensure the background covers the entire div */
  background-size: auto 100%;

  /* Position the background */
  background-position: center;

  /* Make sure the div takes up the entire window */
  width: 100vw;
  height: 100vh;

  /* Optionally prevent the background from repeating */
  background-repeat: no-repeat;

  /* Set a fallback background color */
  background-color: #ffffff;
  /* or any color that suits your design */
}

.container {
  background-color: white;
  position: relative;
  top: 25px;
  border-radius: 5px;
  z-index: 1;
  padding: 10px;
}
</style>
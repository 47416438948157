<template>

  <div>
    <PopupSpinner v-if="submitting"></PopupSpinner>
    <div id="background" :style="submitting ? 'opacity: 0.2;' : 'opacity: 1;'">
      <div class="container col-11 col-md-9 col-xl-6">
        <img id="banner" :src='require(`../assets/banner.png`)' width="100%">
        <b-form @submit.stop.prevent="submitForm">
          <div style="font-size: 20px;"><strong>出席資料</strong></div>

          <b-input-group id="example-input-group-1">
            <b-input-group-prepend is-text>
              <b-icon icon="person-circle"></b-icon>
            </b-input-group-prepend>
            <b-form-input id="example-input-1" name="example-input-1" v-model.trim="$v.form.name.$model" lazy-formatter
              :formatter="checkInBusList" :state="validateState('name')" aria-describedby="input-1-live-feedback"
              placeholder="姓名" :disabled="submitting"></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">名字長度錯誤 (2~15個字)</b-form-invalid-feedback>
          </b-input-group>

          <b-input-group id="example-input-group-2">
            <b-input-group-prepend is-text>
              <b-icon icon="telephone-fill"></b-icon>
            </b-input-group-prepend>
            <b-form-input id="example-input-2" name="example-input-2" v-model="$v.form.phone.$model"
              :state="validateState('phone')" aria-describedby="input-2-live-feedback" placeholder="電話"
              :disabled="submitting"></b-form-input>
            <b-form-invalid-feedback id="input-2-live-feedback">電話格式錯誤 (0987654321,
              0212345678)</b-form-invalid-feedback>
          </b-input-group>

          <b-input-group id="example-input-group-3">
            <b-form-select id="example-input-3" name="example-input-3" v-model="$v.form.type.$model" :options="types"
              :state="validateState('type')" aria-describedby="input-3-live-feedback"
              :disabled="submitting"></b-form-select>
            <b-form-invalid-feedback id="input-3-live-feedback">請選擇您與{{ form.BrideType }}的關係</b-form-invalid-feedback>
          </b-input-group>



          <b-input-group id="example-input-group-4">
            <b-input-group-prepend is-text>
              <span>參加人數</span>
            </b-input-group-prepend>
            <b-form-select id="example-input-4" name="example-input-4" v-model="form.number_people"
              :options="number_people_options" aria-describedby="input-4-live-feedback"
              :disabled="submitting"></b-form-select>
          </b-input-group>



          <b-input-group id="example-input-group-5">
            <b-input-group-prepend is-text>
              <span>吃素人數</span>
            </b-input-group-prepend>
            <b-form-select id="example-input-5" name="example-input-5" v-model="form.number_people_vegetable"
              :options="number_people_limited_options" aria-describedby="input-5-live-feedback"
              :disabled="submitting"></b-form-select>
          </b-input-group>


          <b-form-checkbox id="checkbox-1" v-model="form.NeedPhysicalWeddingInvitation" name="checkbox-1" value="需要"
            unchecked-value="不需要">
            <div style="font-size: 18px;"> 我需要實體喜帖 </div>
          </b-form-checkbox>
          <b-input-group id="example-input-group-6" v-if="form.NeedPhysicalWeddingInvitation === '需要'">
            <b-input-group-prepend is-text>
              <b-icon icon="signpost"></b-icon>
            </b-input-group-prepend>
            <b-form-input id="example-input-6" name="example-input-6" v-model="$v.form.address.$model"
              :state="validateState('address')" aria-describedby="input-6-live-feedback" placeholder="地址"
              :disabled="submitting"></b-form-input>
            <b-form-invalid-feedback id="input-6-live-feedback"> 地址長度錯誤 (最多200個字) </b-form-invalid-feedback>
          </b-input-group>


          <b-form-checkbox id="checkbox-2" v-model="form.NeedChildChair" name="checkbox-2" value="需要"
            unchecked-value="不需要">
            <div style="font-size: 18px;"> 我需要兒童座椅 </div>
          </b-form-checkbox>
          <b-input-group id="example-input-group-6" v-if="form.NeedChildChair === '需要'">
            <b-input-group-prepend is-text>
              <span>兒童座椅數</span>
            </b-input-group-prepend>
            <b-form-select id="example-input-6" name="example-input-6" v-model="form.number_childchair"
              :options="number_people_limited_options" aria-describedby="input-6-live-feedback"
              :disabled="submitting"></b-form-select>
          </b-input-group>


          <div v-if="in_bus_list">
            <b-form-checkbox id="checkbox-3" v-model="form.NeedBus" name="checkbox-3" value="需要" unchecked-value="不需要">
              <div style="font-size: 18px; color: DarkBlue;"> <strong>我需要搭交通車，從10/20當天從高雄來回台中的婚宴場地</strong> </div>
              <div> 詳細搭車時間與地點會另行通知 </div>
            </b-form-checkbox>
            <b-input-group v-if="form.NeedBus === '需要'" id="example-input-group-7">
              <b-input-group-prepend is-text>
                <span>搭車人數</span>
              </b-input-group-prepend>
              <b-form-select id="example-input-7" name="example-input-7" v-model="form.number_people_bus"
                :options="number_people_limited_options" aria-describedby="input-6-live-feedback"
                :disabled="submitting"></b-form-select>
            </b-input-group>
          </div>





          <b-input-group class="row d-flex justify-content-center">
            <b-button type="submit" variant="primary" :disabled="submitting">送出表單</b-button>

            <b-button class="ml-2" variant="secondary" @click="resetForm()" :disabled="submitting">重寫</b-button>
          </b-input-group>


        </b-form>
      </div>

    </div>

  </div>

</template>

<style scoped>
#background {
  z-index: 0;

  background-image: url("@/assets/museum.gif");
  /* Ensure the background covers the entire div */
  background-size: auto 100%;
  /* Position the background */
  background-position: center;

  /* Make sure the div takes up the entire window */
  width: 100vw;
  height: 100vh;

  /* Optionally prevent the background from repeating */
  background-repeat: no-repeat;

  /* Set a fallback background color */
  background-color: #000000;
  /* or any color that suits your design */
}

.info {
  font-size: 16px;
  color: DarkBlue;
}

.info_bulletin {
  font-size: 16px;
  color: white;
  display: inline-block;
}

.container {
  background-color: white;
  position: relative;
  top: 25px;
  border-radius: 5px;
  z-index: 1;
  padding: 10px;
}
</style>

<script>
import { validationMixin } from "vuelidate";
import axios from 'axios';
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators";
import PopupSpinner from '../components/PopupSpinner.vue';


axios.defaults.withCredentials = true;

export default {
  name: "FillForm",
  mixins: [validationMixin],
  props: [
    "view_index",
    "category",
    "type_options",
    "accessToken",
  ],
  components: {
    PopupSpinner,
  },
  data() {
    return {
      form: {
        name: null,
        phone: null,
        BrideType: null,
        type: null,
        NeedPhysicalWeddingInvitation: '不需要',
        address: null,
        NeedChildChair: '不需要',
        number_childchair: 0,
        number_people: 1,
        number_people_vegetable: 0,
        number_people_bus: 0,
        update_time: null,
        NeedBus: false,
      },
      submitting: false,
      number_people_options: [{ value: 1, text: '1' }, { value: 2, text: '2' }, { value: 3, text: '3' }, { value: 4, text: '4' }, { value: 5, text: '5' }, { value: 6, text: '6' }, { value: 7, text: '7' }, { value: 8, text: '8' }, { value: 9, text: '9' }, { value: 10, text: '10' },],
      number_people_limited_options: [{ value: 0, text: '0' }, { value: 1, text: '1' },],
      types: this.type_options,
      in_bus_list: false,
    };
  },
  validations() {
    if (this.form.NeedPhysicalWeddingInvitation === "需要") {
      return {
        form: {
          type: {
            required,
          },
          name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(15),
          },
          phone: {
            required,
            numeric,
            minLength: minLength(7),
            maxLength: maxLength(10),
          },
          address: {
            required,
            maxLength: maxLength(200),
          }
        }
      }
    } else {
      return {
        form: {
          type: {
            required,
          },
          name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(15),
          },
          phone: {
            required,
            numeric,
            minLength: minLength(7),
            maxLength: maxLength(10),
          }
        }
      }
    }

  },
  methods: {
    update_number_people_vegetable_options(number_people) {
      var tmp_number_people_vegetable_options = [];
      for (var i = 0; i <= number_people; i++) {
        tmp_number_people_vegetable_options.push({ value: i, text: i.toString() });
      }
      this.number_people_vegetable_options = tmp_number_people_vegetable_options;
    },
    update_number_people_bus_options(number_people) {
      var tmp_number_people_bus_options = [];
      for (var i = 0; i <= number_people; i++) {
        tmp_number_people_bus_options.push({ value: i, text: i.toString() });
      }
      this.number_people_bus_options = tmp_number_people_bus_options;
    },
    update_number_people_limited_options(number_people) {
      var tmp_number_people_limited_options = [];
      for (var i = 0; i <= number_people; i++) {
        tmp_number_people_limited_options.push({ value: i, text: i.toString() });
      }
      this.number_people_limited_options = tmp_number_people_limited_options;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.$emit('update', 2);
    },
    async submitForm() {
      this.submitting = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.submitting = false;
        return;
      }

      var currentdate = new Date();
      var datetime = currentdate.getFullYear() + "/"
        + (currentdate.getMonth() + 1) + "/"
        + currentdate.getDate() + " "
        + currentdate.getHours() + ":"
        + currentdate.getMinutes() + ":"
        + currentdate.getSeconds();

      this.form.update_time = datetime;
      var data = JSON.stringify({
        "dataSource": "Cluster0",
        "database": "Form",
        "collection": "wedding",
        "document":
          this.form,
      });
      var config = {
        method: 'post',
        url: process.env.VUE_APP_mongodb_url + '/insertOne',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.accessToken,
        },
        data: data
      };

      axios(config)
        .then(response => {
          // console.log(response);
          if (response.status == '201') {
            this.submitting = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          this.submitting = false;
        });

      this.$emit('update', 4);
      this.$emit('update_name', this.form.name);
    },
    checkInBusList(value) { //Is a formater
      var data = JSON.stringify({
        "dataSource": "Cluster0",
        "database": "Form",
        "collection": "bus_list",
        "filter":
          { "name": this.form.name },
      });

      var config = {
        method: 'post',
        url: process.env.VUE_APP_mongodb_url + '/findOne',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.accessToken,
        },
        data: data
      };
      if (this.form.BrideType === '新郎') {
        axios(config)
          .then(response => {
            if (response.status == '200') {
              // console.log(response)
              if (response.data.document !== null) {
                this.in_bus_list = true;
              } else {
                this.in_bus_list = false;
                this.form.number_people_bus = 0;
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      return value;
    }
  },
  watch: {
    'form.number_people': function () {
      // this.update_number_people_vegetable_options(this.form.number_people);
      // this.update_number_people_bus_options(this.form.number_people);
      this.update_number_people_limited_options(this.form.number_people);
      this.form.number_people_vegetable = 0;
      this.form.number_people_bus = 0;
      this.form.number_childchair = 0;
    },
    'form.NeedBus': function () {
      if (this.form.NeedBus == "不需要") {
        this.form.number_people_bus = 0;
      }
    },
    'form.NeedChildChair': function () {
      if (this.form.NeedChildChair == "不需要") {
        this.form.number_childchair = 0;
      }
    },
  },
  beforeMount() {
    if (this.category == 'Bride') {
      this.form.BrideType = "新娘"
    }
    if (this.category == 'Bridegroom') {
      this.form.BrideType = "新郎"
    }

  }
};
</script>
<template>
    <div>
        <PopupSpinner v-if="submitting"></PopupSpinner>
        <div id="background" :style="submitting ? 'opacity: 0.2;' : 'opacity: 1;'">
            <div class="container col-11 col-md-9 col-xl-6 text-center">
                <div v-if="view_index===4">
                    <div style="font-size: 25px;"><strong>填寫成功!!</strong></div>
                    <div style="font-size: 20px;"><strong>10月20日中午見!!</strong></div>

                    <div style="font-size: 20px;"><strong>有什麼話想跟新人說嗎? (自由點選)</strong></div>
                    <b-button class="ml-2" variant="primary" :disabled='submitting' @click="ToRecoredPage">留下祝福的話</b-button>
                </div>
                <div v-if="view_index===6">
                    <div style="font-size: 25px;"><strong>錄音送出成功!!</strong></div>
                </div>
                <div v-if="view_index===7">
                    <div style="font-size: 25px;"><strong>感謝您的祝福!!</strong></div>
                </div>
                <img :src='require(`../assets/Davinci.gif`)' :width="img_width">

            </div>
        </div>
    </div>
</template>

<style scoped>
#background {
  z-index: 0;

  background-image: url("@/assets/museum.gif");
  /* Ensure the background covers the entire div */
  background-size: auto 100%;
  /* Position the background */
  background-position: center;

  /* Make sure the div takes up the entire window */
  width: 100vw;
  height: 100vh;

  /* Optionally prevent the background from repeating */
  background-repeat: no-repeat;

  /* Set a fallback background color */
  background-color: #000000;
  /* or any color that suits your design */
}


.container {
    background-color: white;
    position: relative;
    top: 25px;
    border-radius: 5px;
    z-index: 1;
    padding: 10px;
}
</style>

<script>
import PopupSpinner from '../components/PopupSpinner.vue';

export default {
    name: "Finish",
    components: {
        PopupSpinner,
    },
    data() {
        return {
            submitting: false,
            img_width: '60%',
        }
    },
    created() {
        window.addEventListener("resize", this.ResizePcitureFrame);
    },
    destroyed() {
        window.removeEventListener("resize", this.ResizePcitureFrame);
    },
    mounted() {
        this.ResizePcitureFrame();
    },
    props: [
        "view_index",
    ],
    methods: {
        ToRecoredPage() {
            this.$emit('update', 5);//in the end picture,  change to investigation page
            this.submitting = true;
        },
        ResizePcitureFrame() {
            if (window.innerWidth < 440) {
                this.img_width = '85%';
            } else if (window.innerWidth < 1240) {
                this.img_width = '60%';
            } else {
                this.img_width = '50%';
            }
        }

    }

};
</script>
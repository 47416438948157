<template>
    <div>
        <PopupSpinner v-if="submitting"></PopupSpinner>
        <div id="background" :style="submitting ? 'opacity: 0.2;' : 'opacity: 1;'">
            <div class="container col-11 col-md-9 col-xl-6 text-center">
                <div style="font-size: 20px;"><strong>錄製祝福語音(最多{{ recordTimeLimit }}秒，說完按停止錄音)</strong></div>
                <span style="font-size: 16px;">
                    錄音時長：{{ recorder &&
                    (recorder.duration < recordTimeLimit ? formatNumber(recorder.duration, 2, 2, 0) :
                        formatNumber(recordTimeLimit, 2, 2, 0)) }} </span>
                        <br />

                        <b-button pill v-if="state === 'init'" variant="secondary"
                            :disabled="play_state === 'Playing' || state !== 'init' || !state_mic || submitting"
                            @click="handleStart"><icon-button class="ar-icon ar-icon__sm" name="mic" />开始錄音</b-button>
                        <b-button pill v-if="state === 'Recorded'" variant="secondary"
                            :disabled="play_state === 'Playing' || state !== 'Recorded' || !state_mic || submitting"
                            @click="handleStart"><icon-button class="ar-icon ar-icon__sm" name="mic" />繼續錄音</b-button>
                        <b-button pill v-if="state === 'Recording'" variant="danger"
                            :disabled="play_state === 'Playing' || state !== 'Recording' || !state_mic || submitting"
                            @click="handlePause"><icon-button class="ar-icon ar-icon__sm" name="pause" />暂停錄音</b-button>
                        <b-button pill v-if="state === 'Pause'" variant="danger"
                            :disabled="play_state === 'Playing' || state !== 'Pause' || !state_mic || submitting"
                            @click="handleResume"><icon-button class="ar-icon ar-icon__sm" name="mic" />继续錄音</b-button>
                        <b-button pill variant="secondary"
                            :disabled="play_state === 'Playing' || state !== 'Recording' || !state_mic || submitting"
                            @click="handleStop"><icon-button class="ar-icon ar-icon__sm" name="stop" />停止錄音</b-button>
                        <br />
                        <br />
                        <div
                            v-if="state !== 'Pause' && state !== 'Recording' && (state == 'Recorded' || play_state == 'Playing')">
                            <div class="text-center" style="font-size: 20px;"><strong>再次按下錄音鍵，可以重新錄音</strong></div>



                            <b-button variant="success" :disabled="play_state === 'Playing'|| submitting"
                                @click="handlePlay">
                                <icon-button class="ar-icon ar-icon__sm" name="play" />播放錄音
                            </b-button>
                            <br />
                            <span style="font-size: 16px;" v-if="(state == 'Recorded' || play_state == 'Playing')">
                                播放時長：{{
                                recorder &&
                                (state === 'Recorded' && play_state !== 'Playing'
                                ? formatNumber(0, 2, 2, 0)
                                : formatNumber(playTime, 2, 2, 0))
                                }}</span>
                            <br />
                            <b-button id="submittButton" variant="primary"
                                :disabled="play_state === 'Playing'|| submitting"
                                @click="uploadRecord">送出祝福語音</b-button>
                            <div v-if="errorMsg!==null">{{ errorMsg }}</div>
                        </div>

            </div>
        </div>

    </div>
</template>

<script>
import Recorder from 'js-audio-recorder'
import axios from 'axios';
import { blobToBase64 } from '../utility'
import IconButton from '../components/icon-button.vue'
import PopupSpinner from '../components/PopupSpinner.vue';

export default {
    components: {
        IconButton,
        PopupSpinner,
    },
    props: [
        "accessToken",
        "name",
        "view_index",
    ],
    data() {
        return {
            recorder: null,
            playTime: 0,
            recordedLength: 0,
            timer: null,
            src: null,
            state: "init",
            play_state: null,
            state_mic: true,
            recordTimeLimit: 8,
            submitting: false,
            errorMsg: null,
        }
    },
    created() {
        this.recorder = new Recorder()
    },
    methods: {
        handleStart() {
            this.recorder = new Recorder();
            this.state = "Recording";
            Recorder.getPermission().then(() => {
                console.log('开始錄音');
                this.recorder.start(); // 开始錄音
            }, (error) => {
                try {
                    this.$message({
                        message: '請允許網頁使用麥克風',
                        type: 'info'
                    })
                } catch (e) {
                    this.state_mic = false;
                    this.state = "init";
                    alert("沒有偵測到麥克風設備");
                }

                console.log(`${error.name} : ${error.message}`);
            })
        },
        handlePause() {
            console.log('暂停錄音');
            this.recorder.pause(); // 暂停錄音
            this.state = "Pause";
        },
        handleResume() {
            console.log('恢复錄音');
            this.recorder.resume(); // 恢复錄音
            this.state = "Recording";
        },
        handleStop() {
            console.log('停止錄音');
            this.recorder.stop(); // 停止錄音
            this.state = "Recorded";
            this.recordedLength = this.recorder.duration.toFixed(2);
        },
        handlePlay() {
            this.play_state = "Playing";
            console.log('播放錄音');
            this.recorder.play(); // 播放錄音
            // 播放時長
            this.timer = setInterval(() => {
                try {
                    this.playTime = this.recorder.getPlayTime()
                } catch (error) {
                    this.timer = null
                }
            }, 100);
        },
        handlePausePlay() {
            console.log('暂停播放')
            this.recorder.pausePlay() // 暂停播放

            // 播放時長
            this.playTime = this.recorder.getPlayTime()
            this.time = null
        },
        handleResumePlay() {
            console.log('恢复播放')
            this.recorder.resumePlay() // 恢复播放

            // 播放時長
            this.timer = setInterval(() => {
                try {
                    this.playTime = this.recorder.getPlayTime()
                } catch (error) {
                    this.timer = null
                }
            }, 100)
        },
        handleStopPlay() {
            console.log('停止播放');
            this.recorder.stopPlay(); // 停止播放
            this.playTime = 0;
            // 播放時長
            this.timer = null;
            this.play_state = "Recorded";
            this.playTime = 0;
        },
        handleDestroy() {
            console.log('銷毀handle')
            this.recorder.destroy() // 毁实例
            this.timer = null
        },
        uploadRecord() {
            this.submitting = true;
            if (this.recorder === null || this.recorder.duration === 0) {
                this.$message({
                    message: '请先錄音',
                    type: 'error'
                })
                return false
            }

            this.timer = null
            console.log('上傳錄音')// 上傳錄音



            // const formData = new FormData()
            const blob = this.recorder.getWAVBlob()// 获取wav格式音频数据
            // 此处获取到blob对象后需要设置fileName满足当前项目上傳需求，其它项目可直接传把blob作为file塞入formData
            const newbolb = new Blob([blob], { type: 'audio/wav' })
            const file_name = new Date().getTime() + '.wav';


            blobToBase64(newbolb)
                .then(base64String => {
                    const currentdate = new Date();
                    const datetime = currentdate.getFullYear() + "/"
                        + (currentdate.getMonth() + 1) + "/"
                        + currentdate.getDate() + " "
                        + currentdate.getHours() + ":"
                        + currentdate.getMinutes() + ":"
                        + currentdate.getSeconds();


                    const data = JSON.stringify({
                        "dataSource": "Cluster0",
                        "database": "Form",
                        "collection": "audio",
                        "document":
                        {
                            'name': this.name,
                            'file_name': file_name,
                            'audio_length': this.formatNumber(this.recorder.duration, 2, 2, 0),
                            'update_time': datetime,
                            'audio_file': base64String,
                        },
                    });
                    const config = {
                        method: 'post',
                        url: process.env.VUE_APP_mongodb_url + '/insertOne',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.accessToken,
                        },
                        data: data
                    };
                    axios(config)
                        .then(response => {
                            console.log(response);
                            if (response.status == '201') {
                                this.$emit('update', 6);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            this.errorMsg = "網路錯誤，請在送出一次!";
                            this.submitting = false;
                        });
                    // You can use the base64String here as needed
                })
                .catch(error => {
                    console.error("Error:", error);
                    this.errorMsg = "網路錯誤，請在送出一次!";
                    this.submitting = false;
                });

        },
        formatNumber(number, integerDigits, decimalDigits, floatPointDigits) {
            const roundedNumber = number.toFixed(decimalDigits); // Round to the specified decimal digits
            const [integerPart, decimalPart] = roundedNumber.split('.');

            // Format integer part
            const formattedInteger = integerPart.padStart(integerDigits, '0');

            // Format decimal part
            const formattedDecimal = decimalPart.padEnd(decimalDigits, '0');

            // Format floating-point part
            const formattedFloat = '0'.repeat(floatPointDigits);

            return `${formattedInteger}.${formattedDecimal}${formattedFloat}`;
        },
    },
    watch: {
        "recorder.duration": function () {
            if (this.recorder.duration.toFixed(2) >= 8) {
                this.handleStop();
            }
        },
        "playTime": function () {
            if (this.play_state == "Playing" && this.playTime.toFixed(2) >= this.recordedLength) {
                this.handleStopPlay();
            }
        },

    }
}
</script>

<style scoped>
#background {
  z-index: 0;

  background-image: url("@/assets/museum.gif");
  /* Ensure the background covers the entire div */
  background-size: auto 100%;
  /* Position the background */
  background-position: center;

  /* Make sure the div takes up the entire window */
  width: 100vw;
  height: 100vh;

  /* Optionally prevent the background from repeating */
  background-repeat: no-repeat;

  /* Set a fallback background color */
  background-color: #000000;
  /* or any color that suits your design */
}
#submittButton {
    margin-top: 40px;
}

.container {
    background-color: white;
    position: relative;
    top: 25px;
    border-radius: 5px;
    z-index: 1;
    padding: 10px;
}
</style>
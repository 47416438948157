import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import MainPage from './pages/MainPage.vue'
import ParticipationList from './pages/ParticipationList.vue'
import AudioList from './pages/AudioList.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import JsonExcel from "vue-json-excel2";

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Optionally using VueRouter
Vue.use(VueRouter)
// Optionally using vue-json-excel3
Vue.component("downloadExcel", JsonExcel);




const routes = [
  { path: '/', component: MainPage},
  { path: '/ParticipationList', component: ParticipationList },
  { path: '/AudioList', component: AudioList },
]

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
  mode: 'history',
  routes // (缩写) 相当于 routes: routes
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

<template>

  <div>
    <PopupSpinner v-if="submitting"></PopupSpinner>
    <div id="background" :style="submitting ? 'opacity: 0.2;' : 'opacity: 1;'">
      <div class="container col-11 col-md-9 col-xl-6">
        <b-form @submit.stop.prevent="submitForm">
          <div style="font-size: 20px;"><strong>你的大名</strong></div>

          <b-input-group id="example-input-group-1">
            <b-input-group-prepend is-text>
              <b-icon icon="person-circle"></b-icon>
            </b-input-group-prepend>
            <b-form-input id="example-input-1" name="example-input-1" v-model.trim="$v.form.name.$model" 
              :state="validateState('name')" aria-describedby="input-1-live-feedback"
              placeholder="姓名" :disabled="submitting"></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">名字長度錯誤 (2~15個字)</b-form-invalid-feedback>
          </b-input-group>


          <b-input-group class="row d-flex justify-content-center">
            <b-button type="submit" variant="primary" :disabled="submitting">送出</b-button>
          </b-input-group>


        </b-form>
      </div>

    </div>

  </div>

</template>

<style scoped>
#background {
  z-index: 0;

  background-image: url("@/assets/museum.gif");
  /* Ensure the background covers the entire div */
  background-size: auto 100%;
  /* Position the background */
  background-position: center;

  /* Make sure the div takes up the entire window */
  width: 100vw;
  height: 100vh;

  /* Optionally prevent the background from repeating */
  background-repeat: no-repeat;

  /* Set a fallback background color */
  background-color: #000000;
  /* or any color that suits your design */
}

.info {
  font-size: 16px;
  color: DarkBlue;
}

.info_bulletin {
  font-size: 16px;
  color: white;
  display: inline-block;
}

.container {
  background-color: white;
  position: relative;
  top: 25px;
  border-radius: 5px;
  z-index: 1;
  padding: 10px;
}
</style>

<script>
import { validationMixin } from "vuelidate";
import axios from 'axios';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import PopupSpinner from '../components/PopupSpinner.vue';


axios.defaults.withCredentials = true;

export default {
  name: "NotAttend",
  mixins: [validationMixin],
  props: [
    "view_index",
    "accessToken",
  ],
  components: {
    PopupSpinner,
  },
  data() {
    return {
      form: {
        name: null,
        phone: null,
        BrideType: null,
        type: '不參加',
        NeedPhysicalWeddingInvitation: '不需要',
        address: null,
        NeedChildChair: '不需要',
        number_childchair: 0,
        number_people: 0,
        number_people_vegetable: 0,
        number_people_bus: 0,
        update_time: null,
        NeedBus: false,
      },
      submitting: false,
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(15),
        }
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async submitForm() {
      this.submitting = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.submitting = false;
        return;
      }

      var currentdate = new Date();
      var datetime = currentdate.getFullYear() + "/"
        + (currentdate.getMonth() + 1) + "/"
        + currentdate.getDate() + " "
        + currentdate.getHours() + ":"
        + currentdate.getMinutes() + ":"
        + currentdate.getSeconds();

      this.form.update_time = datetime;
      var data = JSON.stringify({
        "dataSource": "Cluster0",
        "database": "Form",
        "collection": "wedding",
        "document":
          this.form,
      });
      var config = {
        method: 'post',
        url: process.env.VUE_APP_mongodb_url + '/insertOne',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.accessToken,
        },
        data: data
      };

      axios(config)
        .then(response => {
          // console.log(response);
          if (response.status == '201') {
            this.submitting = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          this.submitting = false;
        });

      this.$emit('update', 7);
      this.$emit('update_name', this.form.name);
    },

  },
};
</script>
export function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        // Step 1: Create a new FileReader object
        var reader = new FileReader();

        // Step 2: Define a callback function for when the FileReader has loaded the blob
        reader.onload = function (event) {
            // Step 3: Get the base64 encoded string from the FileReader result
            var base64String = event.target.result;

            // Resolve the promise with the base64 encoded string
            resolve(base64String);
        };

        // Step 4: Define an error handler
        reader.onerror = function (event) {
            // Reject the promise with the error
            reject(event.target.error);
        };

        // Step 5: Read the Blob as a Data URL
        reader.readAsDataURL(blob);
    });
}
export function base64ToBlob(base64String, mimeType) {
    // Split the base64 string into two parts
    var parts = base64String.split(';base64,');
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    // Create a new Uint8Array with the raw string length
    var uInt8Array = new Uint8Array(rawLength);

    // Iterate through the raw string and assign its character codes to the Uint8Array
    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    // Return the Blob object
    return new Blob([uInt8Array], { type: mimeType || contentType });
}
export function convertTimeMMSS(seconds) {
    return new Date(seconds * 1000).toISOString().substring(14, 5)
}


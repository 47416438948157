<template>
    <div id="popup_spinner">
        <div>
            <div class="text-center">
                <strong><span class="text-info" style="font-size: 20px;">處理中...</span></strong>
            </div>
            <div>
                <b-spinner style="width: 8rem; height: 8rem;" variant="info"></b-spinner>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "PopupSpinner",
    data() {
        return {
            left_px: '',
        }
    },
    created() {
        window.addEventListener("resize", this.ResizePcitureFrame);
    },
    destroyed() {
        window.removeEventListener("resize", this.ResizePcitureFrame);
    },
    mounted() {
        this.ResizePcitureFrame();
    },
    methods: {
        ResizePcitureFrame(){
            this.left_px = Math.floor((window.innerWidth - 128) / 2) + 'px';
        }
    }
}
</script>
<style scoped>
#popup_spinner {
    position: absolute;
    top: 30%;
    left: v-bind(left_px);
    border-radius: 10px;
    background-color: whitesmoke;
    z-index: 3;
}
</style>